.wrapperImageWidthBigger {
  @apply h-auto w-full md-max:h-full md-max:w-auto;
  & *:not(.favorites, .adultButton, svg, svg *) {
    @apply h-auto w-full md-max:h-full md-max:w-auto;
  }
}

.wrapperImageHeightBigger {
  @apply h-full w-auto md-max:h-full md-max:w-auto;
  & *:not(.favorites, .adultButton, svg, svg *) {
    @apply h-full w-auto md-max:h-full md-max:w-auto;
  }
}

.favorites {
  @apply !p-1.2;
}
